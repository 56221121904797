import React from "react";

export const Footer = () => {
  return (
    <footer className="w-full bg-slate-900 text-gray-200 py-y">

      <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-center text-center text-gray-200">
        <div className="my-[50px] py-3 text-center">
          <button
            className="py-2 px-2 md:px-4 text-md md:text-2xl text-white bg-transparent"
            onClick={() => { window.location.href = "mailto:info@valiantintelligence.com" }}
          >
            Contact Us
          </button>
        </div>
        <p className="py-4">
          2025 Valiant. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
