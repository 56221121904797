import React from "react";

export const About = () => {
  return (
    <section name="about" className=" w-full bg-gray-900">
      <div className="max-w-[1240px] mx-auto py-32 px-4 text-center">
        <h2 className="text-5xl py-4 my-8 font-bold text-white">
          About Valiant
        </h2>
        <div className="m-4 p-4 ">
          <p className="text-3xl py-2 md:py-6 text-gray-100">
            What is Valiant?
          </p>
          <p className="text-xl md:text-2xl text-gray-200 mt-2">
            Valiant is a technology startup that identified a fundamental flaw in how investment research operates: despite the industry's sophistication, its research process remains fragmented and manual, with highly skilled analysts spending most of their time on low-value tasks. Rather than simply adding another tool to an already cluttered workflow, Valiant aims to transform the entire research process through intelligent automation with bank-level security.
          </p>
        </div>
        <div className="m-4 p-4">
          <p className="text-3xl py-2 md:py-6 text-gray-100">
            What is the Product?
          </p>
          <p className="text-xl md:text-2xl text-gray-200 mt-2">
            Valiant is developing an AI-powered research platform that, for the first time, unifies the entire investment research process into a continuous, self-improving cycle. A living research ecosystem that augments researchers rather than just another traditional software tool.
          </p>
        </div>
        <div className="my-[50px] py-3 text-center">
          <button
            className="py-2 px-2 md:px-4 text-md md:text-2xl text-white bg-transparent"
            onClick={() => { window.location.href = "mailto:info@valiantintelligence.com" }}
          >
            Contact us for more info
          </button>
        </div>

      </div>
    </section>
  )
}
